<template>
  <div v-loading="loading" element-loading-text="正在生成excel，请稍后...">
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>欧西思订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <!-- <p style="float: left;margin: 0;">选择状态：</p> -->
      <el-select
        v-model="select1"
        placeholder="请选择订单状态"
        style="width:100px;margin-right:10px;"
        clearable
        @clear="select1 = -1"
         size="small"
      >
        <el-option v-for="item in options1" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>
      <!-- <p style="float: left;margin: 0;margin-left: 20px;">选择日期：</p> -->
      <el-date-picker
        v-model="date"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        clearable
        style="width:280px;margin-right: 10px;"
         size="small"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-right:10px" @click="getdata2(1)" size="small">查询</el-button>
      <el-button type="primary" @click="exportorder" size="small">导出Excel</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="orderId" label="订单号"></el-table-column>
      <el-table-column prop="tel" label="客户电话"></el-table-column>
      <el-table-column label="医生姓名" prop="docName"></el-table-column>
      <el-table-column label="订单金额" prop="total"></el-table-column>
      <el-table-column label="创建时间" prop="createTime"></el-table-column>
      <el-table-column label="支付状态" prop="status">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.status == 0">未支付</span>
            <span v-if="scope.row.status == 1">已支付</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      >
      </el-pagination>
    </p>
    <el-table :data="tableData" border style="width: 100%" id="tableBox" v-show="false">
      <el-table-column prop="orderId" label="订单号"></el-table-column>
      <el-table-column prop="tel" label="客户电话"></el-table-column>
      <el-table-column label="医生姓名" prop="docName"></el-table-column>
      <el-table-column label="订单金额" prop="total"></el-table-column>
      <el-table-column label="创建时间" prop="createTime"></el-table-column>
      <el-table-column label="支付状态" prop="status">
        <template slot-scope="scope">
          <div class="opt doc">
            <span v-if="scope.row.status == 0">未支付</span>
            <span v-if="scope.row.status == 1">已支付</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
const FileSaver = require("file-saver");
const XLSX = require("xlsx");
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      date: [],
      loading: false,
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      select1: -1,
      options1: [
        { id: 0, value: "未支付" },
        { id: 1, value: "已支付" },
        { id: -1, value: "全部" },
      ],
    };
  },
  mounted() {
    this.getdata2(1);
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata2(currentPage);
      //+"&start="+this.date[0]+"&end="+this.date[1]+"&status="+this.select1
    },
    getdata2(page) {
      var str = "";
      if (this.date == null) {
        this.date = [];
      }
      if (this.select1 != -1) {
        str += "&status=" + this.select1;
      }
      if (this.date != null && this.date.length > 0) {
        str += "&start=" + this.date[0] + "&end=" + this.date[1];
      }
      this.axios
        .get(
          "https://live-server.51xk.info/api/SignUpOrderCount/get_list?index=" +
            page +
            "&pagesize=" +
            this.PageSize +
            str
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.list = res.data.result.list;
            this.totalCount = res.data.result.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    query() {},
    // 执行下载
    async exportorder() {
      await this.setData();
      this.exportOut();
    },
    setData: async function() {
      this.loading = true;
      this.arr = [];
      this.tableData = [];
      let num = Math.ceil(this.totalCount / this.PageSize);
      let m = "";
      let a = [];
      for (m = 1; m <= num; m++) {
        a = await this.funA(m);
        this.arr = this.arr.concat(a.data.result.list);
      }
      this.tableData = this.arr;
    },
    funA: async function(m) {
      var str = "";
      if (this.date == null) {
        this.date = [];
      }
      if (this.select1 != -1) {
        str += "&status=" + this.select1;
      }
      if (this.date != null && this.date.length > 0) {
        str += "&start=" + this.date[0] + "&end=" + this.date[1];
      }
      return await this.axios.get(
        "https://live-server.51xk.info/api/SignUpOrderCount/get_list?index=" +
          m +
          "&pagesize=" +
          this.PageSize +
          str
      );
    },
    //下载表格
    exportOut() {
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var wb = XLSX.utils.table_to_book(document.querySelector("#tableBox"), xlsxParam);
      var wbout = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          new Date().getTime() + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      this.loading = false;
      return wbout;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}
</style>
